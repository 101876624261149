@import "./mediaquery";
@import "./1_variables";

.gis {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;

  .model {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.5);
    z-index: 9999;
    display: flex;

    .container {
      max-width: 30vw;
      width: 100%;
      height: fit-content;
      margin: auto;
      background-color: white;
      border-radius: 5px;
      display: block;
      padding: 1em 2em 1em 2em;

      h3 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        margin: 1em 0 1em 0;
        color: #74465c;
        text-align: center;
      }

      h4 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        padding: 10px 1em 10px 1em;
        width: 100%;
        border-radius: 5px;
        box-shadow: 1px 1px 5px #60606030;
        color: #74465c;
        margin: 12px 0 12px 0;
        cursor: pointer;
      }
      h4:hover {
        box-shadow: 1px 1px 5px #60606070;
      }

      i {
        margin-right: 1em;
      }
    }

    .alert {
      max-width: 30vw;
      width: fit;
      height: fit-content;
      margin: auto auto 50vh auto;
      background-color: white;
      border-radius: 5px;
      display: block;
      padding: 1em 2em 1em 2em;

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        padding: 10px 1em 10px 1em;
        width: 100%;
        border-radius: 5px;
        box-shadow: 1px 1px 5px #60606030;
        color: #74465c;
        margin: 12px 0 12px 0;
      }

      h4 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        padding: 10px 1em 10px 1em;
        width: 100%;
        border-radius: 5px;
        box-shadow: 1px 1px 5px #60606030;
        background-color: $primary;
        color: white;
        margin: 1em 0 12px 0;
        cursor: pointer;
        text-align: center;
      }

      h4:hover {
        background-color: rgba($color: $primary, $alpha: 0.7);
      }
    }
  }

  .search_market {
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 9999;
    background-color: $accent;
    padding: 8px;
    font-size: medium;
    color: white;
    border-radius: 3px;
    cursor: pointer;
  }

  .top_panel {
    display: none;
    position: absolute;
    left: 46px;
    top: 10px;
    background-color: white;
    border-radius: 5px;
    padding: 3px;
    display: grid;
    grid-template-columns: auto auto;
    gap: 5px;
    border: 1px solid $accent;
    width: fit-content;

    select {
      border: none;
      outline: none;
      padding: 4px;
      * {
        font-size: small;
      }
    }
  }

  .BottomPanel {
    position: absolute;
    left: 30%;
    right: 30%;
    bottom: 0;
    height: fit-content;
    z-index: 9999;

    .opts {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      background-color: rgba($color: $primary, $alpha: 0.4);
      border-radius: 5px 5px 0 0;

      h4 {
        color: white !important;
        font-weight: 200 !important;
        height: fit-content;
        text-align: center;
        padding: 8px 0 8px 0;
        cursor: pointer;
      }

      h4:hover {
        color: white !important;
        background-color: $accent;
        border-radius: 5px;
      }
    }

    .Popup {
      height: fit-content;
      z-index: 9999;
      background-color: rgba($color: white, $alpha: 0.9);
      min-height: 40vh;
      max-height: 65vh;
      border-radius: 8px;
      padding: 10px 1em 10px 1em;
      animation: zoom 3s ease;

      p {
        text-align: center;
        font-size: 10pt;
        padding: 0 0 5px 0;
      }

      i {
        color: $dark;
        display: block;
        width: fit-content;
        margin-left: auto;
        cursor: pointer;
      }
      i:hover {
        color: $accent;
      }

      .items {
        width: 100%;

        .input {
          max-width: 200px;
          width: 100%;
          margin: 5px auto 5px auto;

          label {
            display: block;
          }

          input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }

          input {
            width: 100%;
            outline: none;
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom: 1px solid #60606040;
            padding: 8px;
            background-color: transparent;
            color: $dark;
          }
        }

        .aoi {
          display: grid;
          grid-template-columns: auto 1fr;
          gap: 10px;

          p {
            line-height: 44px;
            text-align: left !important;
          }
        }
        h3 {
          text-align: center;
          padding: 8px;
          color: $primary;
          width: 100%;
        }

        h4 {
          font-size: 10pt;
          text-align: center;
          padding: 10px 0 10px 0;
        }

        img {
          height: 44px;
          width: 44px;
          object-fit: contain;
        }

        .imgSelected {
          border: 2px solid $primary;
        }

        .list {
          padding: 1em;
        }

        .item {
          display: grid;
          grid-template-columns: auto 1fr;
          gap: 10px;
          color: $dark;
          margin: 16px 0 16px 0;
        }
      }
    }
    @include maxwidth(mobile) {
      left: 17% !important;
      width: 63vw;
    }
  }

  .map {
    width: 100%;
    height: 100%;

    .map-element {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }

    .ol-zoom {
      position: absolute;
      top: auto;
      bottom: 44px;
      right: 10px;
      left: auto;
      * {
        cursor: pointer;
        background-color: #5889c2;
      }
      *:hover {
        background-color: $accent;
      }
    }

    .ol-zoom-extent {
      position: absolute;
      top: auto;
      bottom: 14px;
      right: 10px;
      left: auto;
      width: fit-content;
      height: fit-content;

      * {
        cursor: pointer;
        background-color: #5889c2;
        font-size: medium;
      }
      *:hover {
        background-color: $accent;
      }
    }

    .ol-rotate {
      position: absolute;
      right: 10px;
      left: auto;
      bottom: 140px;
      top: auto;
      border: 3px solid rgba(210, 190, 167, 0.8);
      padding: 0;

      * {
        cursor: pointer;
        background-color: $accent;
        font-size: medium;
      }
      *:hover {
        background-color: $accent;
      }
    }

    .declutter {
      position: absolute;
      top: 10px;
      left: 42%;
      right: auto;
      bottom: auto;
      background-color: $accent;
      border-radius: 5px;
      padding: 7px;
      gap: 10px;
      cursor: pointer;

      p {
        color: white;
        width: 100%;
        text-align: center;
      }

      input {
        font-size: medium;
      }
    }
    .declutter:hover {
      background-color: $primary;
    }

    .ol-scale-line {
      position: absolute;
      bottom: 10px;
      left: 45%;
      right: auto;
      top: auto;
      background-color: $accent;

      * {
        font-size: medium;
      }
    }

    .download {
      position: absolute;
      bottom: 10px;
      left: auto;
      right: 44px;
      z-index: 9999;
      border-radius: 3px;

      display: grid;
      grid-template-columns: repeat(2, 1fr);

      a {
        border: 3px solid rgba(210, 190, 167, 0.8);
        width: fit-content;
        color: white;
        background-color: #5889c2;
        display: block;
        padding: 3px 10px 3px 10px;
        font-size: small;
        border-radius: 2px;
        cursor: pointer;
        margin: 0 0 0 5px;
      }
      a:hover {
        background-color: $accent;
      }
      i {
        margin-right: 5px;
        font-size: smaller;
      }
    }

    .base_selector {
      position: absolute;
      right: 10px;
      left: auto;
      bottom: 104px;
      top: auto;
      background-color: $accent;
      border: 3px solid rgba(210, 190, 167, 0.8);
      display: flex;
      align-items: center;
      cursor: pointer;
      border-radius: 4px;

      .fa-map {
        padding: 4px 2px 4px;
        color: white;
      }
    }

    .base_selector:hover {
      background-color: $accent;
    }

    .basemap_layers {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($color: white, $alpha: 0.8);
      border: 1px solid $accent;
      z-index: 99999;
      display: flex;
      align-items: center;
      align-content: center;

      .cont {
        width: 100%;
        display: block;
      }

      h3 {
        text-align: center;
        color: $accent;
      }

      .fa-close {
        display: block;
        color: $accent;
        cursor: pointer;
        width: fit-content;
        height: fit-content;
        margin: 0 10px 0 auto !important;
        font-size: large;
      }
      .fa-close:hover {
        color: #74465c;
      }

      .basemaps {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1em;
        padding: 1em;
        width: fit-content;

        p {
          padding: 4px;
          text-align: center;
          font-size: small;
        }

        .active {
          border: 2px solid $accent;
          background-color: white;
          border-radius: 8px;
          cursor: pointer;
          overflow: hidden;
          min-width: 200px;

          img {
            max-width: 100%;
            width: 100%;
            height: 200px;
            object-fit: cover;
          }
        }

        .item {
          background-color: white;
          border-radius: 8px;
          cursor: pointer;
          border: 2px solid transparent;
          overflow: hidden;
          min-width: 200px;

          img {
            max-width: 100%;
            width: 100%;
            height: 200px;
            object-fit: cover;
          }
        }

        .item:hover {
          border: 1px solid $accent;
        }
      }
    }

    .select {
      width: 100%;
      margin: 5px auto 5px auto;

      label {
        display: block;
        font-size: medium;
        color: black;
        font-size: medium;
      }

      select {
        width: 100%;
        outline: none;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid #60606040;
        padding: 4px;
        font-size: small;
        background-color: transparent;
        color: $accent;
      }
    }

    .items {
      width: 100%;

      .link {
        max-width: 85%;
        width: 100%;
        margin: 1em auto 1em auto;

        h4 {
          text-align: center;
          padding: 8px;
          color: $primary;
          width: 100%;
        }

        input {
          width: 100%;
          padding: 1em;
          background-color: transparent;
          border-radius: 5px;
          outline: none;
          box-shadow: 1px 1px 5px #60606030;
          border: 1px solid rgba($color: $dark, $alpha: 0.5);
          margin: 1em 0 0 0;
        }
      }

      .exp {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 10px;
        min-width: 150px;
        width: fit-content;
        cursor: pointer;
        padding: 5px 10px 5px 1em;

        img {
          height: 24px;
          width: 24px;
          object-fit: contain;
        }

        p {
          line-height: 24px;
          text-align: left !important;
        }
      }
      .exp:hover {
        box-shadow: 1px 1px 5px #60606030;
        border-radius: 5px;
      }
      h3 {
        text-align: center;
        padding: 8px;
        color: $primary;
        width: 100%;
      }

      h4 {
        font-size: small;
        text-align: center;
        padding: 10px 0 10px 0;
      }

      img {
        height: 44px;
        width: 44px;
        object-fit: contain;
      }

      .imgSelected {
        border: 2px solid $primary;
      }

      .list {
        padding: 1em;
      }

      .item {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 10px;
        color: $dark;
        margin: 16px 0 16px 0;
      }
    }

    .map_panel {
      position: absolute;
      right: 10px;
      left: auto;
      top: 10px;
      bottom: 174px;
      z-index: 99999;
      background-color: $bg2;
      border-radius: 0 5px 5px 5px;
      box-shadow: 2px 2px 10px #60606030;
      border: 1px solid #60606030;

      .fa-angle-left,
      .fa-angle-right {
        padding: 8px 10px 8px 10px;
        background-color: $accent;
        cursor: pointer;
        position: absolute;
        top: 0px;
        left: -32px;
        width: 32px;
        color: white;
        border-radius: 5px 0 0 5px;
      }
      .fa-angle-left:hover,
      .fa-angle-right:hover {
        background-color: $accent;
      }
      .collapsible {
        width: 250px;
        background-color: $bg2;
        overflow: hidden;
        display: block;

        .bar {
          background-color: $accent;
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          border-radius: 0 5px 0 0;

          p {
            font-size: medium !important;
            padding: 8px 10px 8px 10px;
            color: white;
            text-align: center;
            cursor: pointer;
          }

          p:hover {
            color: $accent;
            background-color: $bg2;
          }

          .active {
            font-size: small;
            padding: 8px 10px 8px 10px;
            color: $accent;
            background-color: $bg2;
            text-align: center;
            cursor: pointer;
          }
        }

        .r_layers {
          padding: 1em;

          hr {
            color: #74465c;
          }

          p {
            font-size: medium !important;
          }

          h4 {
            font-size: medium;
            margin-bottom: 1em;
            color: $accent;
          }

          h5 {
            margin: 5px 10px 5px 3em;
            font-size: medium;
          }

          .theme {
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 5px;
            margin: 5px 10px 5px 4em;
            font-size: small;
            font-style: italic;
            color: $accent;

            input {
              height: 12px;
              width: 12px;
              border: none;
              outline: none;
            }
          }

          .item {
            display: grid;
            grid-template-columns: auto 1fr auto auto auto;
            gap: 5px;
            margin: 10px 0 10px 0;
            font-size: small;

            .fa-arrows-alt {
              transform: rotate(45deg);
            }

            .fa {
              padding: 2px;
              color: $accent;
              cursor: pointer;
            }
            .fa:hover {
              color: #74465c;
            }
          }
        }

        .items {
          padding: 0 4px;
          h5 {
            color: $dark;
            padding: 10px 0 5px 0;
          }
        }
      }
      .outer {
      }
    }

    .visualization {
      position: absolute;
      z-index: 9999;
      left: 10px;
      top: 10px;
      width: fit-content;

      select {
        width: 100%;
        background-color: rgba($color: #fff, $alpha: 1);
        border-radius: 4px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: small;
        padding: 5px;
        text-align: center;
        color: $primary;
        border: 1px solid #60606030;
        box-shadow: 1px 1px 5px #60606030;
        cursor: pointer;
        display: block;
        outline: none;
        border-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cstyle%3Epath%7Banimation:stroke 5s infinite linear%3B%7D%40keyframes stroke%7Bto%7Bstroke-dashoffset:776%3B%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='0%25' stop-color='%232d3561' /%3E%3Cstop offset='25%25' stop-color='%23c05c7e' /%3E%3Cstop offset='50%25' stop-color='%23f3826f' /%3E%3Cstop offset='100%25' stop-color='%23ffb961' /%3E%3C/linearGradient%3E %3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='3' stroke-dasharray='388'/%3E %3C/svg%3E")
          1;
      }
    }

    // .legend {
    //   position: absolute;
    //   bottom: 10px;
    //   left: 10px;
    //   width: fit-content;
    //   height: fit-content;
    //   z-index: 999;
    //   box-shadow: 2px 2px 10px #60606030;
    //   border: 1px solid #60606030;
    //   border-radius: 5px;
    //   padding: 1em;
    //   background-color: $bg2;

    //   hr {
    //     margin: 3px 0 3px 0;
    //   }

    //   .it {
    //     display: grid;
    //     grid-template-columns: auto 1fr;
    //     gap: 10px;
    //     margin: 3px 0 3px 0;

    //     img {
    //       width: 24px;
    //       object-fit: contain;
    //       margin: auto;
    //       display: block;
    //     }
    //   }
    // }

    .download {
      position: absolute;
      bottom: 13px;
      right: 40px;
      z-index: 9999;

      display: grid;
      grid-template-columns: repeat(2, 1fr);

      // a {
      //   width: fit-content;
      //   color: white;
      //   background-color: #5889c2;
      //   margin: 0 0 0 5px;
      //   display: block;
      //   padding: 3px 10px 3px 10px;
      //   font-size: small;
      //   border-radius: 2px;
      //   cursor: pointer;
      // }

      a:hover {
        background-color: #217de6;
      }

      i {
        margin-right: 5px;
        font-size: smaller;
      }

      @include maxwidth(mobile) {
        right: 54px !important;
      }

      @include maxwidth(tablet) {
        right: 54px !important;
      }
    }

    .legend {
      position: absolute;
      left: 10px;
      bottom: 30px;
      padding: 10px;
      border-radius: 10px;
      background-color: rgba($color: #fff, $alpha: 0.8);

      .cwrap {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 10px;
        font-size: x-small;
        margin-bottom: 3px;

        .circle {
          border: 2px solid yellow;
          background-color: aqua;
          height: 16px;
          width: 16px;
          border-radius: 24px;
          font-size: x-small;
          text-align: center;
          line-height: 16px;
        }
      }

      .rwrap {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 10px;
        font-size: x-small;

        .circle {
          border: 2px solid grey;
          background-color: green;
          height: 16px;
          width: 16px;
          border-radius: 24px;
          font-size: x-small;
          text-align: center;
          line-height: 16px;
        }
      }

      .hwrap {
        width: 120px;
        gap: 10px;
        font-size: x-small;

        .circle {
          border: 2px solid yellow;
          background-image: linear-gradient(to right, red, green);
          height: 16px;
          width: 100%;
          border-radius: 24px;
          font-size: x-small;
          text-align: center;
          line-height: 16px;
        }
      }

      .gwrap {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 10px;
        font-size: x-small;

        .male {
          border: 2px solid yellow;
          background-color: blue;
          height: 16px;
          width: 16px;
          border-radius: 24px;
          font-size: x-small;
          text-align: center;
          line-height: 16px;
          margin-bottom: 10px;
        }

        .female {
          border: 2px solid yellow;
          background-color: purple;
          height: 16px;
          width: 16px;
          border-radius: 24px;
          font-size: x-small;
          text-align: center;
          line-height: 16px;
        }
      }

      .awrap {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 10px;
        font-size: x-small;

        .a1 {
          border: 2px solid yellow;
          background-color: #0088fe;
          height: 16px;
          width: 16px;
          border-radius: 24px;
          font-size: x-small;
          text-align: center;
          line-height: 16px;
          margin-bottom: 10px;
        }

        .a2 {
          border: 2px solid yellow;
          background-color: #00c49f;
          height: 16px;
          width: 16px;
          border-radius: 24px;
          font-size: x-small;
          text-align: center;
          line-height: 16px;
          margin-bottom: 10px;
        }

        .a3 {
          border: 2px solid yellow;
          background-color: #ffbb28;
          height: 16px;
          width: 16px;
          border-radius: 24px;
          font-size: x-small;
          text-align: center;
          line-height: 16px;
        }
      }
    }

    .query {
      background-color: $bg2;
      border-radius: 5px;
      max-width: 250px;
      width: 100%;

      .bar {
        background-color: $accent;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        border-radius: 5px 5px 0 0;

        p {
          font-size: small;
          padding: 4px 6px 4px 6px !important;
          color: white;
          text-align: center;
          cursor: pointer;
        }

        p:hover {
          color: $accent;
          background-color: $bg2;
          border-radius: 5px 5px 0 0;
        }

        .active {
          color: $accent;
          background-color: $bg2;
          cursor: pointer;
          border-radius: 5px 5px 0 0;
        }
      }

      .dat_cont {
        padding: 1em;
      }

      .basic_styler {
        padding: 10px;

        .query {
          width: 100% !important;
          height: fit-content !important;
          padding: 4px !important;
          font-size: small;
          margin: auto;
          display: block;
          border: 1px solid $accent !important;
          border-radius: 5px;
          outline: none;
        }
        button {
          padding: 6px 1em 6px 1em;
          background-color: $accent;
          color: white;
          margin: 12px auto 0 auto;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          display: block;
          min-width: 100px;

          .fa {
            color: white;
          }
        }

        .buttons {
          width: fit-content;
          margin: 5px 0 5px auto;
          display: grid;
          grid-template-columns: repeat(2, auto);
          gap: 10px;

          .fa {
            cursor: pointer;
            color: $accent;
            font-size: medium;
            padding: 3px;
          }
          .fa:hover {
            color: #74465c;
          }
        }

        input[type="number"] {
          padding: 8px 10px 8px 10px !important;
          border: 1px solid $accent !important;
          border-radius: 5px;
          color: $accent !important;
          min-width: 44px;
          text-align: center;
        }

        p {
          color: $accent;
          font-size: small;
        }

        .classes {
          max-height: 28vh;
          height: 100%;
          overflow-y: auto;
        }

        .fa {
          color: $accent;
          padding: 3px;
        }

        .row {
          display: grid;
          grid-template-columns: auto 1fr auto auto;
          gap: 10px;
          margin: 5px 0 5px 0;

          input {
            width: 16px;
            height: 16px;
            border: none;
            cursor: pointer;
            display: block;
            margin: auto;
          }

          p {
            font-size: small;
            display: block;
            width: 100%;
            margin: auto;
          }

          select {
            padding: 3px;
            background-color: $bg2;
            border: 1px solid $accent;
            border-radius: 3px;
            cursor: pointer;
            font-size: smaller;
            color: $accent;
          }
        }
      }

      .split {
        display: grid;
        grid-template-columns: auto auto !important;

        input {
          width: 24px;
        }
      }

      h3 {
        font-size: small;
        font-weight: 500;
        color: black;
        margin: 7px 0 5px 0;
      }

      br {
        height: 1px !important;
        padding: 0;
        margin: 0;
      }

      hr {
        color: #74465c;
        margin-bottom: 8px;
        margin-top: 8px;
      }

      .fa-close {
        display: block;
        margin: 0 0 0 auto;
        color: $accent;
        cursor: pointer;
        width: fit-content;
      }
      .fa-close:hover {
        color: #74465c;
      }
    }

    // .popup {
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   bottom: 0;
    //   height: 100%;
    //   width: 100%;
    //   background-color: rgba($color: #000000, $alpha: 0.5);
    //   display: flex;
    //   align-items: center;
    //   z-index: 999999;
    //   animation: zoom 1s ease;
  
    //   .container {
    //     background-color: white;
    //     border-radius: 5px;
    //     padding: 10px;
    //     margin: 0 auto 0 auto;
    //     color: #000000;
    //     max-width: 70vw;
    //     max-height: 70vh;
    //     height: fit-content;
    //     overflow-y: auto;
    //     width: 100%;
    //     position: relative;

    //     .dets {
    //       display: grid;
    //       grid-template-columns: 1fr auto;
    
    //       .fa {
    //         color: $accent;
    //         height: fit-content;
    //         margin: auto;
    //         padding: 5px;
    //       }
    //       .fa:hover {
    //         color: $primary;
    //       }
    //     }
  
    //     .bar {
    //       display: grid;
    //       grid-template-columns: repeat(5, 1fr);
    //       background-color: $accent;
    //       color: white;
    //       padding: 2PX 0 !important;
  
    //       p {
    //         text-align: center;
    //         margin: 0 !important;
    //         cursor: pointer;
    //         font-size: larger;
    //       }
  
    //       .active {
    //         background-color: wheat;
    //         color: $accent;
    //       }
  
    //       p:hover {
    //         background-color: wheat;
    //         color: $accent;
    //       }
  
    //       @include maxwidth(mobile) {
    //         grid-template-columns: repeat(2, 1fr) !important;
    //       }
    //       @include maxwidth(tablet) {
    //         grid-template-columns: repeat(3, 1fr) !important;
    //       }
    //     }
  
    //     .content {
    //       position: relative;
    //       min-height: 30vh;
  
    //       .tally {
    //         position: absolute;
    //         top: 5px;
    //         right: 0;
  
    //         .active {
    //           background-color: #74465c;
    //         }
  
    //         p {
    //           background-color: $accent;
    //           border-radius: 2px;
    //           cursor: pointer;
    //           width: 32px;
    //           height: 32px;
    //           text-align: center;
    //           color: white;
    //         }
    //         p:hover {
    //           background-color: #74465c;
    //         }
    //       }
  
    //       @include maxwidth(mobile) {
    //         display: block !important;
  
    //         p {
    //           word-wrap: break-word !important;
    //         }
    //       }
    //       @include maxwidth(tablet) {
    //         display: block !important;
    //         p {
    //           word-wrap: break-word !important;
    //         }
    //       }
    //     }
  
    //     .confirm {
    //       padding-top: 20px;
    //       text-align: center;
    //       p {
    //         width: 100%;
    //       }
  
    //       .success {
    //         color: $green;
    //       }
  
    //       .isError {
    //         color: red;
    //       }
  
    //       button {
    //         display: block;
    //         margin: 1em auto 1em auto;
    //         // padding: 12px;
    //         // width: 80%;
    //         background-color: $green;
    //         color: white;
    //         border-top: none;
    //         border-right: none;
    //         border-left: none;
    //         border-bottom: 2px solid #60606070;
    //         border-top: 2px solid #60606070;
    //         cursor: pointer;
    //       }
  
    //       button:hover {
    //         background-color: rgba($color: $primary, $alpha: 0.8);
    //       }
  
    //       .newMember {
    //         margin: 1em auto 0 auto;
    //         text-decoration: none;
    //         font-size: larger;
    //         background-color: $accent;
    //         color: white;
    //         cursor: pointer;
    //         height: fit-content;
    //         display: block;
    //         padding: 6px;
    //         border-radius: 4px;
    //         box-shadow: 1px 1px 5px #60606030;
    //       }
  
    //       .newMember:hover {
    //         background-color: $primary;
    //       }
    //     }
  
    //     .search {
    //       padding-top: 10px;
    //       text-align: center;
  
    //       input {
    //         margin-top: 10px;
    //       }
  
    //       .search_list {
    //         margin-top: 2px;
    //         cursor: pointer;
    //       }
    //     }
  
    //     h3 {
    //       text-align: center;
    //       font-size: x-large !important;
    //     }
  
    //     .fa-times {
    //       position: absolute;
    //       right: 1em;
    //       top: 10px;
    //       padding: 10px;
    //       cursor: pointer;
    //       color: $primary;
    //     }
    //     .fa-times:hover {
    //       color: $accent;
    //     }
  
    //     hr {
    //       margin-bottom: 10px;
    //     }
  
    //     i {
    //       display: block;
    //       width: fit-content;
    //       padding: 5px;
    //       margin: 0 0 0 auto;
    //       color: $accent;
    //     }
    //     i:hover {
    //       color: $primary;
    //     }
  
    //     h3 {
    //       padding: 0px 0 10px 0;
    //       font-size: medium;
    //     }
  
    //     form {
    //       textarea {
    //         resize: none;
    //         width: 100%;
    //         margin: auto;
    //         padding: 4px;
    //         font-size: 18px;
    //       }
    //     }
  
    //     .input-map {
    //       margin: 10px 0 10px 0;
  
    //       h4 {
    //         display: block;
    //         margin: 10px 0 10px 0;
    //         font-weight: 400;
    //       }
    //       textarea {
    //         margin: 0px 0 10px 0;
    //         padding: 10px;
    //         width: 100%;
    //         background-color: #60606030;
    //         border-top: none;
    //         border-right: none;
    //         border-left: none;
    //         outline: none;
    //         border-bottom: 2px solid #60606070;
    //       }
    //       textarea:focus {
    //         border-bottom: 2px solid $primary;
    //       }
    //       input {
    //         margin: 0px 0 10px 0;
    //         padding: 10px;
    //         width: 100%;
    //         background-color: #60606030;
    //         border-top: none;
    //         border-right: none;
    //         border-left: none;
    //         outline: none;
    //         border-bottom: 2px solid #60606070;
    //       }
    //       input:focus {
    //         border-bottom: 2px solid $primary;
    //       }
    //     }
    //     .usrselect {
    //       margin: 10px 0 10px 0;
  
    //       h4 {
    //         display: block;
    //         margin: 10px 0 10px 0;
    //         font-weight: 400;
    //       }
  
    //       select {
    //         margin: 0px 0 10px 0;
    //         padding: 10px;
    //         width: 100%;
    //         background-color: #60606030;
    //         border-top: none;
    //         border-right: none;
    //         border-left: none;
    //         outline: none;
    //         border-bottom: 2px solid #60606070;
    //       }
    //       input:focus {
    //         border-bottom: 2px solid $primary;
    //       }
    //     }
  
    //     p {
    //       font-size: medium;
    //       margin: 0px 0 5px 0;
  
    //       b {
    //         color: #74465c;
    //         cursor: pointer;
    //       }
    //     }
  
    //     button {
    //       display: block;
    //       margin: 1em auto 1em auto;
    //       padding: 12px;
    //       width: 80%;
    //       background-color: $primary;
    //       color: white;
    //       border-top: none;
    //       border-right: none;
    //       border-left: none;
    //       border-bottom: 2px solid #60606070;
    //       border-top: 2px solid #60606070;
    //       cursor: pointer;
    //     }
    //     button:hover {
    //       background-color: rgba($color: $primary, $alpha: 0.8);
    //     }
    //   }
    //   @include maxwidth(mobile) {
    //     .container {
    //       max-width: 85% !important;
    //     }
    //   }
    //   @include maxwidth(tablet) {
    //     .container {
    //       max-width: 85% !important;
    //     }
    //   }
  
    //   .content {
    //     display: grid;
    //     grid-template-columns: repeat(2, 1fr);
    //     gap: 5px;
    //   }
  
    //   hr {
    //     margin-bottom: 10px;
    //   }
    // }

    .popup {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      background-color: rgba($color: #000000, $alpha: 0.5);
      display: flex;
      align-items: center;
      z-index: 999999;
      animation: zoom 1s ease;
  
      .container {
        background-color: white;
        border-radius: 5px;
        padding: 10px;
        margin: auto;
        color: #000000;
        min-width: 50vw;
        max-width: 70vw;
        min-height: 50vh;
        height: fit-content;
        overflow-y: auto;
        width: fit-content;
        position: relative;
  
        .bar {
          display: grid;
          grid-template-columns: repeat(6, 1fr);
          background-color: $accent;
          color: white;
          padding: 0 !important;
          margin-bottom: 10px;
  
          p {
            text-align: center;
            margin: 0 !important;
            cursor: pointer;
          }
  
          .active {
            background-color: wheat;
            color: $accent;
          }
  
          p:hover {
            background-color: wheat;
            color: $accent;
          }
  
          @include maxwidth(mobile) {
            grid-template-columns: repeat(2, 1fr) !important;
          }
          @include maxwidth(tablet) {
            grid-template-columns: repeat(3, 1fr) !important;
          }
        }
  
        .ppmap {
          margin: auto;
          width: 50vw;
          height: 60vh;
          border-radius: 3px;
          box-shadow: 1px 1px 5px #60606030;
          overflow: hidden;
          .pmap{
            width: 100%;
            height: 100%;
            border-radius: 8px;
            box-shadow: 1px 1px 5px #60606030;
            overflow: hidden;
          }
        }
  
        .pcontent {
          position: relative;
          min-height: 30vh;
  
          .prow {
            display: grid;
            grid-template-columns: 1fr 4fr;
            border-bottom: 1px solid #60606030;
    
            :first-child {
              background-color: #60606010 !important;
              padding: 2px !important;
              font-size: small !important;
            }
            p {
              padding: 2px !important;
              font-size: small !important;
            }
          }
  
          .tally {
            position: absolute;
            top: 5px;
            right: 0;
  
            .active {
              background-color: #74465c;
            }
  
            p {
              background-color: $accent;
              border-radius: 2px;
              cursor: pointer;
              width: 32px;
              height: 32px;
              text-align: center;
              color: white;
            }
            p:hover {
              background-color: #74465c;
            }
          }
  
          @include maxwidth(mobile) {
            display: block !important;
  
            p {
              word-wrap: break-word !important;
            }
          }
          @include maxwidth(tablet) {
            display: block !important;
            p {
              word-wrap: break-word !important;
            }
          }
        }
  
        .col2 {
          column-count: 2; /* Number of columns you want */
          column-gap: 20px; /* Gap between columns */
          max-height: 300px; /* Set the maximum height of the parent container */
          overflow: hidden; /* Hide overflow content */
        }
  
        .confirm{
          padding-top: 20px;
          text-align: center;
          p{
            width: 100%;
          }
  
          .success {
            color: $green;
          }
  
          .isError {
            color: red;
          }
        
          // button {
          //   // display: block;
          //   // margin: 1em auto 1em auto;
          //   // // padding: 12px;
          //   // // width: 80%;
          //   // background-color: $green;
          //   // color: white;
          //   // border-top: none;
          //   // border-right: none;
          //   // border-left: none;
          //   // border-bottom: 2px solid #60606070;
          //   // border-top: 2px solid #60606070;
          //   // cursor: pointer;
          // }
  
          button:hover {
            background-color: rgba($color: $primary, $alpha: 0.8);
          }
  
          .newMember{
            margin:1em auto 0 auto;
            text-decoration: none;
            font-size: larger;
            background-color: $accent;
            color: white;
            cursor: pointer;
            height: fit-content;
            display: block;
            padding: 6px;
            border-radius: 4px;
            box-shadow: 1px 1px 5px #60606030;
          }
  
          .newMember:hover{
            background-color: $primary;
          }
        }
  
        .search{
          padding-top: 10px;
          text-align: center;
  
          input{
            margin-top: 10px;
          }
  
          .search_list{
            margin-top: 2px;
            cursor: pointer;
          }
        }
  
        h3 {
          text-align: center;
          font-size: x-large !important;
        }
  
        .fa-times {
          position: absolute;
          right: 1em;
          top: 10px;
          padding: 10px;
          cursor: pointer;
          color: $primary;
        }
        .fa-times:hover {
          color: $accent;
        }
  
        hr {
          margin-bottom: 10px;
        }
  
        i {
          display: block;
          width: fit-content;
          padding: 5px;
          margin: 0 0 0 auto;
          color: $accent;
        }
        i:hover {
          color: $primary;
        }
  
        h3 {
          padding: 0px 0 10px 0;
          font-size: medium;
        }
  
        form {
          textarea {
            resize: none;
            width: 100%;
            margin: auto;
            padding: 4px;
            font-size: 18px;
          }
        }
  
        .input-map {
          margin: 10px 0 10px 0;
  
          h4 {
            display: block;
            margin: 10px 0 10px 0;
            font-weight: 400;
          }
          textarea {
            margin: 0px 0 10px 0;
            padding: 10px;
            width: 100%;
            background-color: #60606030;
            border-top: none;
            border-right: none;
            border-left: none;
            outline: none;
            border-bottom: 2px solid #60606070;
          }
          textarea:focus {
            border-bottom: 2px solid $primary;
          }
          input {
            margin: 0px 0 10px 0;
            padding: 10px;
            width: 100%;
            background-color: #60606030;
            border-top: none;
            border-right: none;
            border-left: none;
            outline: none;
            border-bottom: 2px solid #60606070;
          }
          input:focus {
            border-bottom: 2px solid $primary;
          }
        }
        .usrselect {
          margin: 10px 0 10px 0;
  
          h4 {
            display: block;
            margin: 10px 0 10px 0;
            font-weight: 400;
          }
  
          select {
            margin: 0px 0 10px 0;
            padding: 10px;
            width: 100%;
            background-color: #60606030;
            border-top: none;
            border-right: none;
            border-left: none;
            outline: none;
            border-bottom: 2px solid #60606070;
          }
          input:focus {
            border-bottom: 2px solid $primary;
          }
        }
  
        p {
          font-size: medium;
          margin: 0px 0 5px 0;
  
          b {
            color: #74465c;
            cursor: pointer;
          }
        }
  
        // button {
        //   // display: block;
        //   // margin: 1em auto 1em auto;
        //   // padding: 12px;
        //   // width: 80%;
        //   // background-color: $primary;
        //   // color: white;
        //   // border-top: none;
        //   // border-right: none;
        //   // border-left: none;
        //   // border-bottom: 2px solid #60606070;
        //   // border-top: 2px solid #60606070;
        //   // cursor: pointer;
        // }
        button:hover {
          background-color: rgba($color: $primary, $alpha: 0.8);
        }
      }
      @include maxwidth(mobile) {
        .container {
          max-width: 85% !important;
        }
      }
      @include maxwidth(tablet) {
        .container {
          max-width: 85% !important;
        }
      }
  
      .dets {
        display: grid;
        grid-template-columns: 1fr auto;
  
        .fa {
          color: $accent;
          height: fit-content;
          margin: auto;
          padding: 5px;
        }
        .fa:hover {
          color: $primary;
        }
      }
  
      .content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 5px;
      }
  
      hr {
        margin-bottom: 10px;
      }
    }
  }

  .cont {
    padding: 2em;

    .input-map {
      label {
        display: block;
        font-size: medium;
        color: $dark;
        margin: 1em 0 10px 0;
      }
      input {
        width: 100%;
        padding: 1em;
        background-color: transparent;
        border-radius: 5px;
        outline: none;
        box-shadow: 1px 1px 5px #60606030;
        border: 1px solid rgba($color: $dark, $alpha: 0.5);
        margin: 1em 0 0 0;
      }
    }

    .div2equal {
      gap: 2em !important;
      margin: 2em 0 2em 0;
    }

    .img {
      position: relative;
      width: 100%;
      height: calc(25vw * 0.6);

      img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: contain;
        object-position: center;
        border-radius: 5px;
      }

      input {
        display: none;
      }

      i {
        height: 40px;
        width: 40px;
        text-align: center;
        line-height: 40px;
        left: 45%;
        position: absolute;
        bottom: -20px;
        cursor: pointer;
        background-color: $accent;
        border-radius: 5px;
      }
      i:hover {
        background-color: $dark;
        color: $accent;
      }

      @include maxwidth(tablet) {
        height: 200px !important;
      }
    }

    h3 {
      font-size: 18px;
      line-height: 1.3;
      max-width: 80%;
      color: $primary;
      text-align: center;
      display: block;
      margin: 2em auto 10px auto;
    }

    p {
      font-size: 14px;
      line-height: 1.3;
      max-width: 60%;
      display: block;
      margin: 10px auto 10px auto;
      text-align: center;
    }
  }

  @include maxwidth(mobile) {
    .legend,
    .download,
    .ol-scale-line {
      display: none;
    }

    .declutter {
      left: 30% !important;
    }
  }

  @include maxwidth(tablet) {
    .legend,
    .download,
    .ol-scale-line {
      display: none;
    }

    .declutter {
      left: 30% !important;
    }
  }

  .zoom_out{
    animation: zoomout 1s ease;
  }
}

@keyframes zoom {
  0% {
    transform: scale(0, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes zoomout {
 0% {
    top: 0;
    opacity: 1;
  }
  100% {
    top: -300px;
    opacity: 0;
  }
}
