@import "./variables";
@import "./mediaquery";

.login {
  background-color: rgba($color: white, $alpha: 1);
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: relative;
  overflow: hidden;

  .coffee-medium__smoke {
    width: 8px;
    height: 20px;
    position: absolute;
    border-radius: 5px;
    background-color: #b3aeae;
  }
  @keyframes smokeOne {
    0% {
      bottom: 20px;
      opacity: 0;
    }
    40% {
      right: 40vh;
      opacity: 0.5;
    }
    80% {
      bottom: 70vh;
      opacity: 0.3;
    }
    100% {
      bottom: 90vh;
      opacity: 0;
    }
  }
  @keyframes smokeTwo {
    0% {
      top: 40px;
      opacity: 0;
    }
    40% {
      left: 40vh;
      opacity: 0.5;
    }
    80% {
      top: 70vh;
      opacity: 0.3;
    }
    100% {
      top: 90vh;
      opacity: 0;
    }
  }
  .coffee-medium__smoke-one {
    opacity: 0;
    bottom: 50px;
    left: 10vw;
    animation: smokeOne 3s 5s radial infinite;
  }
  .coffee-medium__smoke-two {
    opacity: 0;
    top: 70px;
    left: 30vw;
    animation: smokeTwo 3s 5s linear infinite;
  }
  .coffee-medium__smoke-three {
    opacity: 0;
    bottom: 65px;
    right: 30vw;
    animation: smokeTwo 3s 6s linear infinite;
  }
  .coffee-medium__smoke-for {
    opacity: 0;
    bottom: 50px;
    right: 10vw;
    animation: smokeOne 3s 5s linear infinite;
  }
  .mob {
    display: none;
  }

  .leftdeco {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 50%;
    background-color: rgba($color: $primary, $alpha: 0.8);

    .right_panel {
      img {
        max-width: 50vw;
        width: 100%;
        height: 100vh;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  .card {
    max-width: 70%;
    width: 100%;
    display: block;
    margin: auto;
    padding: 2em;
    box-shadow: 10px 10px 40px 40px rgba(0, 0, 0, 0.05);
    z-index: 999;
    border-radius: 10px;
    overflow: hidden;
    position: relative;

    .container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1em;
      height: 100%;

      .left {
        display: grid;
        grid-template-rows: repeat(4, 1fr);
        align-items: center;
        height: 100%;
        margin: auto;
        z-index: 999;
        text-align: center;
        padding-right: 1em;


       

        h1 {
          font-size: xx-large;
          color: white;
        }
        h3 {
          // margin: auto 0 auto 1.5em;
          font-size: x-large;
          color: white;
        }

        p {
          width: fit-content;
          display: block;
          margin: 10px auto 0 auto;
          color: white;
        }
        a {
          width: fit-content;
          display: block;
          margin: 0 auto 0 auto;
          color: white;
          cursor: pointer;
        }
        a:hover {
          color: $primarylight;
        }
      }

      .right {
        position: relative;
        padding: 2em;
        background-color: white;

        .input {
          label {
            display: block;
            margin: 10px 0 10px 0;
              font-size: medium;
          }

          input {
            margin: 0px 0 10px 0;
            padding: 10px;
            width: 100%;
            background-color: #60606030;
            border-top: none;
            border-right: none;
            border-left: none;
            outline: none;
            border-bottom: 2px solid #60606070;
          }
          input:focus{
             border-bottom: 2px solid $primary;
          }
        }

        img {
          max-height: 150px;
          height: 100%;
          object-fit: contain;
          display: block;
          margin: 0 auto 0 auto;
        }

        .err {
          height: 24px;
          line-height: 24px;
          text-align: center;
          color: $accent;
          font-size: small;
        }
        h3 {
          text-align: center;
          font-family: "Inter";
          font-style: normal;
          font-size: x-large;
          text-align: center;
          color: $secondary;
        }
        h5 {
          height: 12px;
          font-size: small;
          text-align: center;
          margin: 8px 0 8px 0;
          color: $accent;
        }
        p {
          font-size: medium;
          text-align: center;
          color: black;
          a {
            color: $accent;
            cursor: pointer;
          }
          a:hover {
            color: $secondary;
          }
        }

        button {
          display: block;
          margin: 1em auto 1em auto;
          padding: 12px;
          width: 80%;
          background-color: $primary;
          color: white;
          border-top: none;
          border-right: none;
          border-left: none;
          border-bottom: 2px solid #60606070;
          border-top: 2px solid #60606070;
          cursor: pointer;
        }
        button:hover {
          background-color: rgba($color: $primary, $alpha: 0.8);
        }
      }
    }
  }
  .ld {
    position: absolute;
    top: 260px;
    bottom: 0;
    left: 0;
    right: 0;
    // height: 40vh;
  }

  @include maxwidth(mobile) {
    .mob {
      display: block;
      text-align: center;
      position: absolute;
      top: 1em;
      left: 0;
      right: 0;
      z-index: 999;

      * {
        margin-top: 10px;
        font-size: large;
        font-weight: bolder;
      }
    }
    .leftdeco {
      right: 0 !important;

      .right_panel {
        img {
          max-width: 100vw !important;
        }
      }
    }

    .card {
      max-width: 85vw !important;
      padding: 0 !important;
      .container {
        grid-template-columns: 1fr !important;
        .left {
          display: none !important;
        }
        .right {
          padding: 2em !important;
          background-color: rgba($color: white, $alpha: 0.8) !important;
        }
      }
    }
  }

  @include maxwidth(tablet) {
    .mob {
      display: block;
      text-align: center;
      position: absolute;
      top: 1em;
      left: 0;
      right: 0;
      z-index: 999;
    }
    .leftdeco {
      right: 0 !important;

      .right_panel {
        img {
          max-width: 100vw !important;
        }
      }
    }

    .card {
      max-width: 85vw !important;
      padding: 0 !important;
      .container {
        grid-template-columns: 1fr !important;
        .left {
          display: none !important;
        }
        .right {
          padding: 2em !important;
          background-color: rgba($color: white, $alpha: 0.8) !important;
        }
      }
    }
  }
}

.contain {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba($color: #000000, $alpha: 0.5);
  display: flex;
  align-items: center;
  z-index: 9999;

  .card1 {
    background-color: white;
    border-radius: 8px;
    padding: 1em 1em 1em 1em;
    margin: 0 auto 0 auto;
    color: #000000;
    max-width: 400px;
    max-height: 90vh;
    width: 100%;
    position: relative;
    overflow-y: scroll;

    .title {
      .msg {
        text-align: center;
        color: green;
        font-size: 16px;
      }
      .err {
        text-align: center;
        color: red;
        font-size: 16px;
      }
    }

    h3 {
      font-size: large;
      margin: 10px 0 10px 0;
      text-align: center;
    }

    form {
      textarea {
        resize: none;
        width: 100%;
        margin: auto;
        padding: 4px;
        font-size: 18px;
      }
    }

    .body {
      form {
        textarea {
          resize: none;
          width: 100%;
          margin: auto;
          padding: 4px;
          font-size: 18px;
        }
        label {
          display: block;
          font-size: medium;
          margin: 10px 0 10px 0;
        }
        input {
          width: 100%;
          background-color: transparent;
          border-radius: 4px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: small;
          padding: 4px;
          text-align: center;
          color: black;
          border: 1px solid gray;
          cursor: pointer;
          display: block;
          margin: 10px auto 10px auto;
        }
        ::placeholder {
          color: #000000;
          // opacity: 0.8;
        }
      }
    }

    p {
      font-size: 12px;
      margin: 10px 0 10px 10px;

      span {
        color: #74465c;
        cursor: pointer;
      }
    }

    h4 {
      color: #d01c1c;
      height: 14px;
      font-size: 12px;
      line-height: 14px;
      display: block;
      text-align: center;
      margin: 10px 0 10px 0;
      cursor: pointer;
    }

    .submit {
      background-color: $primary;
      width: 50%;
      min-width: 150px;
      cursor: pointer;
      border-radius: 4px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: small;
      padding: 5px;
      text-align: center;
      color: #ffffff;
      border: none;
      cursor: pointer;
      display: block;
      margin: 1em auto 1em auto;
    }
    button:hover {
      background-color: $primarylight;
    }

    .delete:hover {
      background-color: #d01c1c;
    }
  }
  // @include maxwidth(mobile){
  //   .container{
  //     margin: 0 2em 0 2em !important;

  //   }
  // }
}
