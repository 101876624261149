$primary: #0693e3;
$secondary: #0693e3;
$accent: #fcb900;
$dark: #0693e3;
$darkComplimentary: #c192f0;
$bg1: #f0f4f9;
$bg2: #f5f4f0;
$lightPrimary: #0693e3;
$greyPrimary: #d5e3f6;
$green: #00b32a;
